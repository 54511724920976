import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialogClose,
} from '@angular/material/dialog';
import { IConfirmationDialogConfig } from './confirmation-dialog';
import { NgClass, NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { AutoFocusDirective } from 'src/app/directives/auto-focus/auto-focus.directive';

@Component({
  selector: 'confirmation-dialog',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    MatDialogClose,
    MatIconModule,
    AutoFocusDirective,
    MatButtonModule,
  ],
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmationDialogComponent implements OnInit {
  /**
   * Constructor
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IConfirmationDialogConfig,
    public matDialogRef: MatDialogRef<ConfirmationDialogComponent>
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------
}
