import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { HttpService } from '../core/http/services/http.service';
import { ToastService } from '../components/toast/toast.service';
import { ConfirmationDialogService } from '../components/confirmation-dialog/confirmation-dialog.service';
import { ApiMethod } from '../core/http/api';

@Injectable({
  providedIn: 'root',
})
export class ReusableService {
  constructor(
    private _http: HttpService,
    private _toastService: ToastService,
    private _confirmationDialogService: ConfirmationDialogService,
    private _translateService: TranslateService,
    private http: HttpClient
  ) {}

  getDetail(apiUrl: string, id: string) {
    return this._http.request({
      apiUrl: apiUrl,
      method: ApiMethod.Post,
      body: {
        id: id,
      },
    });
  }

  get(apiUrl: string, body: any) {
    return this._http.request({
      apiUrl: apiUrl,
      method: ApiMethod.Post,
      body: body,
    });
  }

  save(apiUrl: string, payload: any) {
    return new Observable((observer) => {
      this._http
        .request({
          apiUrl: apiUrl,
          method: ApiMethod.Post,
          body: payload,
        })
        .subscribe(
          (res) => {
            this._toastService.open({
              message: this._translateService.instant(
                'Services.SaveSuccessDefault'
              ),

              configs: {
                payload: {
                  type: 'success',
                },
              },
            });
            observer.next(res);
            observer.complete();
          },
          (err) => {
            observer.error(err);
          }
        );
    });
  }

  cancel(apiUrl: string, payload: any) {
    return new Observable((observer) => {
      this._http
        .request({
          apiUrl: apiUrl,
          method: ApiMethod.Post,
          body: payload,
        })
        .subscribe(
          (res) => {
            this._toastService.open({
              message: this._translateService.instant(
                'Services.CancelSuccessDefault'
              ),

              configs: {
                payload: {
                  type: 'success',
                },
              },
            });
            observer.next(res);
            observer.complete();
          },
          (err) => {
            observer.error(err);
          }
        );
    });
  }

  post(
    apiUrl: string,
    payload: any,
    update?: boolean,
    showToast: boolean = true,
    message?: string
  ) {
    return new Observable((observer) => {
      this._http
        .request({
          apiUrl: apiUrl,
          method: ApiMethod.Post,
          body: payload,
        })
        .subscribe(
          (res) => {
            if (showToast) {
              this._toastService.open({
                message: message
                  ? this._translateService.instant(message)
                  : update || payload.id
                  ? this._translateService.instant(
                      'Services.UpdateSuccessDefault'
                    )
                  : this._translateService.instant(
                      'Services.CreateNewSuccessDefault'
                    ),
                configs: {
                  payload: {
                    type: 'success',
                  },
                },
              });
            }

            observer.next(res);
            observer.complete();
          },
          (err) => {
            observer.error(err);
          }
        );
    });
  }

  changeStatus(apiUrl: string, id: string) {
    return new Observable((observer) => {
      this._http
        .request({
          apiUrl: `${apiUrl}`,
          method: ApiMethod.Post,
          body: {
            id: id,
          },
        })
        .subscribe(
          (res) => {
            this._toastService.open({
              message: this._translateService.instant(
                'Services.ChangeStatusSuccess'
              ),
              configs: {
                payload: {
                  type: 'success',
                },
              },
            });
            observer.next(res);
            observer.complete();
          },
          (err) => {
            observer.error(err);
          }
        );
    });
  }

  delete(apiUrl: string, id: any) {
    return new Observable((observer) => {
      const confirmRef = this._confirmationDialogService.open({
        message: this._translateService.instant(
          'Services.DeleteConfirmDefault'
        ),
        title: this._translateService.instant('Button.Confirm'),
        actions: {
          cancel: {
            show: true,
            label: this._translateService.instant('Button.Cancel'),
          },
          confirm: {
            show: true,
            label: this._translateService.instant('Button.Confirm'),
            color: 'accent',
          },
        },
      });

      confirmRef.afterClosed().subscribe((type: string) => {
        if (type === 'confirmed') {
          this._http
            .request({
              apiUrl: `${apiUrl}`,
              method: ApiMethod.Post,
              body: {
                id: id,
              },
            })
            .subscribe(
              (res) => {
                this._toastService.open({
                  message: this._translateService.instant(
                    'Services.DeleteSuccessDefault'
                  ),
                  configs: {
                    payload: {
                      type: 'success',
                    },
                  },
                });
                observer.next(res);
                observer.complete();
              },
              (err) => {
                observer.error(err);
              }
            );
        }
      });
    });
  }

  uploadImages(
    image: File,
    id: { [k: string]: string | number },
    apiUrl: string
  ): Observable<any> {
    const formData = new FormData();
    formData.append(Object.keys(id)[0], Object.values(id)[0] as string);
    formData.append('PostedFile', image);

    return this.http.post(apiUrl, formData);
  }

  formData(apiUrl: string, payload: any = {}): Observable<any> {
    const formData = new FormData();
    for (let key in payload) {
      if (Array.isArray(payload[key])) {
        for (let item of payload[key]) {
          formData.append(key, item);
        }
      } else {
        formData.append(key, payload[key]);
      }
    }

    return this.http.post(apiUrl, formData);
  }
}
